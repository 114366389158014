<template>
  <Component class="disabled:cursor-not-allowed material-symbols-sharp disabled:opacity-50 text-grayble" :is="componentToShow" :to="props.href">
    <slot></slot>
  </Component>
</template>

<script setup lang="ts">
// Imports
import { NuxtLink } from '#components'

// Interface
export interface Props{
  href?: string | object
}

// Props
const props = withDefaults(defineProps<Props>(), {})

// Component to show
const componentToShow = computed(() => {
  // If href link, else button
  return props.href ? NuxtLink : 'button'

})
</script>